.elementsAltre {
	color: black;
}
.boxAltrecert {
	padding-top: 20px;
}

.simbolEl {
	color: #cfcfcf;
	font: icon;
	font-weight: bolder;
}

.boxElAl {
	padding-bottom: 5px;
}

/* ------- */
.mainContainer {
	background-color: none !important;
	overflow: auto !important;
	/* margin-top: 25px !important; */
}

.card-title {
	margin-bottom: 0.75rem;
	font-weight: bolder;
	text-align-last: left;
	font-size: 16px;
}
.card-text:last-child {
	margin-bottom: 0;
	text-align: left;
}
.row {
	margin-top: 20px;
}

.figure-img {
	height: 200px;
}

@media (min-width: 1224px) {
	.imgBox {
		height: 120px !important;
	}

	.mediaStyle {
		width: -webkit-fill-available;
	}

	.boxServizi {
		margin: 10px;
	}
}

@media only screen and (max-width: 768px) {
	.figure-img {
		height: auto !important;
	}
	.imgBox {
		height: 150px;
	}
}

@media (max-width: 768px) {
	.imgBox {
		height: 120px !important;
	}
	.mediaStyle {
		width: 280px;
	}
}

@media (max-width: 1224px) {
	.imgBox {
		height: 120px !important;
	}
	.mediaStyle {
		width: 586px;
	}
	.boxServizi {
		margin: 10px;
	}
}

@media (max-width: 1000px) {
	.mediaStyle {
		width: 403px;
	}
}

@media (max-width: 663px) {
	.mediaStyle {
		width: 540px;
	}
}

@media (max-width: 624px) {
	.mediaStyle {
		width: 440px;
	}
}

@media (max-width: 523px) {
	.mediaStyle {
		width: 340px;
	}
}

@media (max-width: 427px) {
	.mediaStyle {
		width: 300px;
	}
}

@media (max-width: 360px) {
	.mediaStyle {
		width: 280px;
	}
	input.searchBarra {
		width: 310px;
	}
	select.optionSel {
		width: 310px;
	}
}

.immagineNotaio {
	width: -webkit-fill-available;
}

.boxServizi {
	background: #ffffff;
	width: -webkit-fill-available;
	height: 150px;
	text-align: -webkit-center;
	display: inline-table;
	margin-bottom: 20px;
	border-radius: 5px;
	border-bottom: none;
}

.post_content {
	line-height: 32px;
}

.servizio_nome {
	border-bottom: 1px solid;
	padding: 5px;
	font-weight: bolder;
	font-feature-settings: 'pcap', 'c2pc', 'pcap', 'c2pc', 'pcap', 'c2pc', 'pcap',
		'c2pc';
	font-variant: all-petite-caps;
	font-size: 25px;
	margin-bottom: 0px;
	padding-bottom: 7px;
	border-color: #000000;
}
.servizioBox {
	font-size: 15px;
	padding: 5px;
	width: -webkit-fill-available;
	margin: 0px;
}

.servizio_tipo_1 {
	background: rgb(255, 136, 136);
}
.servizio_tipo_2 {
	background: rgb(95, 183, 255);
}
.servizio_tipo_3 {
	background: rgb(255, 239, 149);
}
.servizio_tipo_4 {
	background: rgb(137, 255, 186);
}

.servizio_tag {
	font-size: 12px;
	background: #ffffff;
	padding: 3px;
	width: fit-content;
	margin: 7px;
	border-radius: 5px;
	border-style: outset;
	text-transform: uppercase;
}

.servizio_link {
	font-size: 15px;
	padding: 3px;
	width: fit-content;
	margin: 30px;
	margin-bottom: 0px;
	text-align: justify;
	margin-top: 0px;
}

.searchBarra {
	border-radius: 3px;
	background: #f9f9f9;
	padding: 10px;
	border-style: double;
	margin: 10px;
}

.tipoBarra {
	margin: 10px;
}

.barraLista {
	display: inline-grid;
	align-items: center;
}

.serviceList {
	padding: 10px;
	text-align: center;
	margin: 5px;
}

.approfondisci:hover {
	cursor: help;
	color: red;
}

.titleBarraLista {
	text-transform: uppercase;
	padding: 10px;
	margin: 0px;
}

.menuItem {
	border-bottom: inset;
	padding: 5px;
	background: #0000008c;
	font-size: 15px;
	width: -webkit-fill-available;
	border-color: white !important;
}

.menuItem:hover {
	padding-bottom: 8px;
	border-bottom: inset;
	width: -webkit-fill-available;
	color: white !important;
	border-color: rgb(255, 191, 0) !important;
}

.titleTop {
	font-size: 30px;
	/* color: white; */
}

.a_link_custom {
	color: #ffffff;
	font-weight: 200;
	transition: all 0.5s ease-in-out;
	text-transform: uppercase;
}

.a_link_custom:hover {
	color: #ffffff;
	font-weight: 400;

	transition: all 0.5s ease-in-out;
	text-transform: uppercase;
}

.box_services {
	padding-top: 20px;
}

.optionSel {
	padding: 5px;
}

.subTitleCustum {
	color: #fff;
	padding: 20px;
	font-weight: 400;
	font-size: 21px;
	transition: all 0.5s ease-in-out;
	text-transform: uppercase;
}

.imgBox {
	height: 260px;
}
.back {
	background-color: white;
	background-size: cover;
	/* box-shadow: 0px 0px 200px 150px rgb(0 0 0) inset; */
}
.boxButtonInfo {
	display: flex;
	justify-content: center;
	background: #ffffff;
	padding-top: 4px;
	margin: 0px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.servizio_link_info {
	font-size: 12px;
	padding: 10px;
	padding-bottom: 6px;
	padding-top: 6px;
	background: white;
	width: 150px;
	margin: 7px;
	border-style: outset;
	text-transform: uppercase;
}

.secBack {
	min-height: 100vh;
	background: white;
	color: black;
}
